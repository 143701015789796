import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

//Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { LinkButton } from '../components/_parts/LinkButton'

export default () => {
  const queryData = useStaticQuery(graphql`
    {
      allProjet {
        edges {
          node {
            name
            tags
            slug
          }
        }
      }
      allImageSharp(filter: {fluid: {originalName: {in: ["tim.png","borne.png","traces.png"]}}}) {
        edges {
          node {
            fluid(maxWidth: 766) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)

  const projets = queryData.allProjet.edges
  const images = queryData.allImageSharp.edges.map(edge => edge.node.fluid)

  const blockElements = projets.map(edge => {
    const { name, tags, slug } = edge.node
    const image = images.filter(img => img.originalName === `${slug}.png`)

    return (
      <div className="projets__bloc">
        <Img fluid={image} style={{ maxWidth: '766px', height: 'auto' }} />
        <h3>{name}</h3>
        <div className="projets__bloc__tags">
          {tags.map(tag => (
            <span>#{tag} </span>
          ))}
        </div>
      </div>
    )
  })

  return (
    <Layout className="page-projets">
      <SEO title="Projets" />
      <h1 className="page-title">
        <span className="page-title__hollow-bold">Mes projets</span>
        <br />
        <span className="page-title__red-line">
          Un aperçu <span className="page-title__dot black">.</span>
        </span>
      </h1>
      <hr style={{ border: "solid #B8B8B8 1px", width: "100%", margin: "0 auto" }} />
      <div className="projets">
        {blockElements}
      </div>
      <LinkButton title="INTERESSÉ ?" page="contact" />
    </Layout>
  )
}